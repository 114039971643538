<template>
    <div class="landing-wrapper">
        <t-header />
        <div class="container space-top-2 space-bottom-2">
            <div class="w-lg-60 text-center mx-lg-auto">
                <div class="mb-5 mb-md-11">
                    <h2>About Us</h2>
                    <p>
                        Nice to meet you, we are Thriftax – the leading online
                        U.S. tax preparation solution for Nonresident Aliens.
                        Our team specializes in two things – taxes and
                        technology! When we found out that many foreign
                        nationals, including international students,
                        researchers, professors, and other professionals are
                        struggling with their U.S. tax preparation, we decided
                        to create an online self-filing software. Unlike any
                        other service provider in the market, our solution is
                        truly user friendly, advanced, smooth, and of course
                        100% accurate! Our commitment to excellence and customer
                        satisfaction is uncompromising. We stand behind our
                        product and are proud to provide a 100% accuracy refund
                        policy.
                    </p>
                    <p>
                        We are constantly looking to improve. Listening to our
                        customers is an integral part of that process. If you
                        have an insight, feedback to share, or would like to
                        connect with us for any other reason, please do not
                        hesitate to contact us.
                    </p>
                </div>
            </div>
        </div>
        <t-footer />
    </div>
</template>

<script>
export default {
    data: function () {
        return {};
    },
    metaInfo: {
        title: 'Thriftax - About',
        meta: [
            {
                name: 'description',
                content: 'The complete tax filing solution for nonresidents.',
            },
        ],
    },
};
</script>

<style scoped>
.payment {
    width: 400px;
}

p {
    font-size: 16px !important;
}

/* iphone x portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    /* STYLES GO HERE */
    .payment {
        width: 250px;
    }
}

/* iphone 5 & 5s portrait */
@media only screen and (max-device-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
    .payment {
        width: 250px;
    }
}
</style>
